<template>
    <div class="l-inline l-center topography-background public-layout">
        <div class="public-layout__card">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PublicLayout',
}
</script>

<style lang="scss" scoped>
.public-layout {
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: $color-gray-light;
    overflow-y: auto;

    &__card {
        margin: 0 auto;
        width: 375px;
        border-left: $style-border;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.04),
            -3px 20px 80px rgba(0, 0, 0, 0.3);
    }
}
</style>
